import React, { useState } from 'react';
import { ArrowRight, ExternalLink } from 'lucide-react';
import logo from './assets/logo.png';
import preview1 from './assets/preview1.png';

const PolyscapeLanding = () => {
    const [activeTab, setActiveTab] = useState('home');
    const tabs = ['home', 'mogulplex', 'trading', 'videos', 'p'];

    const newsItems = {
        mogulplex: [
            {
                title: "MOGULPLEX Open Beta Releasing April 2025",
                content: "",
                date: "November 30, 2024",
                category: "Development"
            }
        ]
    };

    const renderNewsSection = (section) => {
        const items = newsItems[section] || [];
        return items.length > 0 ? (
            <div className="mt-16">
                <h3 className="text-2xl font-light mb-6 glow-text">News</h3>
                <div className="space-y-6">
                    {items.map((item, index) => (
                        <article key={index} className="border-l-4 border-white/20 pl-4 hover:border-white/40 transition-colors">
                            <h4 className="text-xl font-bold mb-2">{item.title}</h4>
                            <p className="text-gray-400 mb-2">{item.content}</p>
                            <div className="flex items-center text-sm text-gray-500">
                                <time>{item.date}</time>
                                <ArrowRight className="w-4 h-4 mx-2" />
                                <span>{item.category}</span>
                            </div>
                        </article>
                    ))}
                </div>
            </div>
        ) : null;
    };

    const renderContent = () => {
        switch(activeTab) {
            case 'home':
                return (
                    <div className="fade-in">
                        <div className="space-y-8">
                            {Object.entries(newsItems).map(([section, items]) => (
                                <div key={section} className="space-y-6">
                                    {items.map((item, index) => (
                                        <article key={index} className="border-l-4 border-white/20 pl-4 hover:border-white/40 transition-colors">
                                            <h4 className="text-xl font-bold mb-2">{item.title}</h4>
                                            <p className="text-gray-400 mb-2">{item.content}</p>
                                            <div className="flex items-center text-sm text-gray-500">
                                                <time>{item.date}</time>
                                                <ArrowRight className="w-4 h-4 mx-2" />
                                                <span>{item.category}</span>
                                            </div>
                                        </article>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                );

            case 'mogulplex':
                return (
                    <div className="fade-in">
                        <h2 className="text-3xl font-light mb-6 glow-text">MOGULPLEX.COM</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div className="space-y-6">
                                <p className="text-gray-300 text-lg leading-relaxed hover-bright">
                                    MOGULPLEX provides a 3D space for artists to showcase their work and express themselves.
                                    A unique blend of creativity and technical innovation, MOGULPLEX.COM is a
                                    platform that empowers individuals to create a lasting impression with a global audience.
                                </p>
                                <div className="space-y-4 fade-up">
                                    <a href="https://mogulplex.com"
                                       className="flex items-center text-blue-400 hover:text-blue-300 transition-colors"
                                       target="_blank" rel="noopener noreferrer">
                                        <ExternalLink className="w-4 h-4 mr-2" />
                                        Visit MOGULPLEX.COM
                                    </a>
                                    <a href="https://youtube.com/@mogulplex"
                                       className="flex items-center text-red-400 hover:text-red-300 transition-colors"
                                       target="_blank" rel="noopener noreferrer">
                                        <ExternalLink className="w-4 h-4 mr-2" />
                                        @mogulplex on YouTube
                                    </a>
                                </div>
                            </div>
                            <div className="relative transform hover:scale-105 transition-transform duration-300">
                                <img
                                    src={preview1}
                                    alt="MOGULPLEX Preview"
                                    className="rounded-lg shadow-2xl hover:shadow-[0_0_15px_rgba(255,255,255,0.2)]"
                                />
                            </div>
                        </div>
                        {renderNewsSection('mogulplex')}
                    </div>
                );

            case 'p':
            case 'trading':
            case 'videos':
                return (
                    <div className="fade-in">
                        <div className="flex items-center justify-center h-64">
                            <p className="text-2xl text-gray-500 font-light tracking-wider">COMING SOON</p>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <div className="min-h-screen bg-black text-white">
            <header className="bg-white py-16">
                <div className="max-w-6xl mx-auto px-2">
                    <div className="flex items-center">
                        <img
                            src={logo}
                            alt="Polyscape Digital Asset Management"
                            className="h-24 hover:scale-105 transition-transform duration-300"
                        />
                    </div>
                </div>
            </header>

            <nav className="border-b border-gray-800 mt-8">
                <div className="max-w-6xl mx-auto px-4">
                    {/* Updated navigation container */}
                    <div className="overflow-x-auto scrollbar-hide">
                        <div className="min-w-max md:min-w-0 md:w-full grid grid-cols-5 w-full">
                            {tabs.map((tab) => (
                                <button
                                    key={tab}
                                    onClick={() => setActiveTab(tab)}
                                    className={`py-4 px-6 md:px-2 text-sm uppercase tracking-wider transition-all flex justify-center items-center whitespace-nowrap
                                        ${activeTab === tab
                                        ? 'text-white border-b-2 border-white glow-border'
                                        : 'text-gray-500 hover:text-gray-300'}`}
                                >
                                    {tab.replace('-', ' ').charAt(0).toUpperCase() + tab.replace('-', ' ').slice(1)}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </nav>

            <main className="max-w-6xl mx-auto px-4 py-16">
                {renderContent()}
            </main>

            <footer className="border-t border-gray-800 py-12">
                <div className="max-w-6xl mx-auto px-4">
                    <div className="flex justify-between items-center">
                        <p className="text-gray-500 hover:text-gray-300 transition-colors">© 2024 Polyscape Digital Asset Management</p>
                        <p className="text-gray-500 hover:text-gray-300 transition-colors">All rights reserved</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default PolyscapeLanding;